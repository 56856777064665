import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import zeus from '../assets/zeus.png';
import about from '../assets/about-subHeader-Yellow.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH"><img src={about}/></div>

                    <div class="about">

                        <img class="zeus" src={zeus} />

                        <div class="aboutP">
                            <p>Welcome to Power of Zeus DAO! Were very happy to have you here and be witness to a NFT project like no other! In my experience in the NFT space I've seen a lot of project lack in building funds for the community wallet.</p>
                            <p>Here we plan you take a portion of the funds generated from the mint and we our reinvesting it in Defi projects, these projects will produce stable reliable daily yields that the community will be able to track and determine what we do with the funds that our produced</p>
                            <p>These funds will be used for Advertising like Twitter ads & Google ads & Facebook ads - Buybacks/Sweeping the floor - Merch - Pay to Earn Game or Compounding our reward to reinvest to make more daily yields. At the end of day we want our COMMUNITY to make all decisions on what we do with the funds generated, That will be done by a voting process we'll have a list of ideas produced by the COMMUNITY & TEAM.</p>
                            <p>We want everyone to have a say so of what happens with Power Of Zeus. And to top the cake we're giving a 100% of the royalties back to the "COMMUNITY WALLET"!</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Story;

