import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import line from '../assets/line.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

//<div class="devs"><a href="https://www.fiverr.com/appslkofficial" target="_blank">Meet the Devs Here</a> <img src={dev}/></div>

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">
                <img src={line}/>
                 <div class="footer">
                    <div class="logoF">Power of Zeus DAO</div>
                    <div class="copyright">Copyright © 2023 Power of Zeus DAO. All Rights Reserved</div>                   
                </div>

            </div>
        )
    }
}

export default Footer;

